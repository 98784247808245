import ReactDOM from 'react-dom'
import styled from 'styled-components'
import XButton from '../../assets/modalAssets/web-x.png'
import CeremonyImage from '../../assets/modalAssets/ceremony.png'
import CeremonyHandType from '../../assets/modalAssets/ceremony-handwritten.png'
import BridesRoomHandType from '../../assets/modalAssets/brides-room-handwritten.png'
import BanquetHandType from '../../assets/modalAssets/banquet-handwritten.png'
import ReceptionHandType from '../../assets/modalAssets/reception-handwritten.png'
import BridesRoom from '../../assets/modalAssets/brides-room.png'
import Banquet from '../../assets/modalAssets/banquet.png'
import ReceptionTree from '../../assets/modalAssets/reception-tree.png'
import ModalConnectorImg from '../../assets/modalAssets/modal-connector.svg'

const CloseButton = styled.button`
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`

const CloseImage = styled.img`
  position: fixed;
  width: 43px;
  height: auto;
  max-width: 62px;
  right: 20px;
  top: 20px;

  @media(min-width: 940px) {
    width: 62px;
  }
`

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: white;
  overflow-y: auto;
`

const ModalContent = styled.div`
  padding: 20px;

  @media(max-width: 940px) { 
    margin-bottom: 100px;
  }
`

const VenueDescription = styled.div`
  max-width: 280px;
  margin-left: 8px;
  margin-top: -20px;

  @media(min-width: 940px) {
    max-width: 581px;
  }
`

const ContentPara = styled.p`
  margin-bottom: 15px;
  font-size: 18px;
`

const DescriptionPara = styled(ContentPara)`
  @media (max-width: 940px) {
    max-width: 133px;
  }
`

const VenuePic1 = styled.img`
  width: 100%;
`

const ModalAssetGroup = styled.div`
  ${props => props.mobileDirectionLayout === 'right' ? 'margin-right: auto' : 'margin-left: auto'};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.mobileDirectionLayout === 'right' ? 'flex-start' : 'flex-end'};
  padding-right: 20px;

  @media(min-width: 940px){
    position: absolute;
    padding-right: 0;
  }
`

const FirstModalAssetGroup = styled(ModalAssetGroup)`
  max-width: 280px;
  @media(min-width: 940px) {
    top: 23%
  }
`

const SecondModalAssetGroup = styled(ModalAssetGroup)`
  @media(min-width: 940px) {
    bottom: -13%;
    flex-direction: row;
    left: 33%
  }
`

const ThirdModalAssetGroup = styled(ModalAssetGroup)`
  @media(min-width: 940px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    max-width: 350px;
    right: 32%;
  }
`

const FourthModalAssetGroup = styled(ModalAssetGroup)`
  max-width: 460px;
  @media(min-width: 940px) {
    right: 0;
    max-width: 300px;
    bottom: 30%;
  }
`

const ModalAssetGroups = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  padding-left: 40px;
  padding-top: 35px;
  padding-right: 0;
  padding-bottom: 0;

  @media(max-width: 400px) {
    padding-left: 0;
  }

  @media(min-width: 940px) {
    padding-left: 0;
    position: relative;
    max-width: 1000px;
    max-height: 700px;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: -143px;
  }

  @media(min-width: 1400px) {
    max-width: 1100px;
  }
`

const ImageDescription = styled.div`
  padding-top: 20px;
`

const SecondImageDescription = styled(ImageDescription)`
  @media(min-width: 940px){
    margin-bottom: 34px;
  }
`

const ThirdImageDescription = styled(ImageDescription)`
  @media(min-width: 940px){
    margin-left: 200px;
  }
`

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 20px;
  max-width: 280px;

  @media(min-width: 940px) {
    flex-direction: row;
    padding: 0;
    max-width: 100%;
  }
`

const DetailsParas1 = styled.div`
  @media(min-width: 940px) {
    max-width: 280px;
    padding-left: 30px;
    padding-top: 30px;
  }
`

const DetailPara2 = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;

  @media(min-width: 940px) {
    margin-left: 20px;
    padding-top: 30px;
  }
`

const AddressInfo = styled.p`
  margin-bottom: 15px;
  font-size: 18px;
  z-index: 9999;

  @media(min-width: 940px) {
    position: fixed;
    bottom: 0;
    max-width: 200px;
    padding-left: 30px;
    padding-bottom: 50px;
  }
`

const ModalConnector = styled.img`
  display: none;
  @media(min-width: 940px) {
    display: block;
    position: absolute;
    right: 0;
    margin: auto;
    top: 59%;
    left: -10%;
  }

  @media(max-width: 1040px) {
    width: 500px;
  }
`

const HandType = styled.img`
  width: 73px;
`

//750 px breakpoint to start desktop?

const VenueModal = ({language, closeModal, modalContentData}) => {
  const getContentText = (index, textType, backupText) => {
    if (modalContentData.length > index) {
      const content = modalContentData[index].item[textType][language]
      return content === undefined ? backupText : content
    }
    return backupText
  }

  return ReactDOM.createPortal(
    <ModalWrapper className="MODAL">
      <ModalContent>
        <CloseButton onClick={closeModal}><CloseImage src={XButton} /></CloseButton>
        <VenueDescription>
          <DetailsSection>
            <DetailsParas1>
              <ContentPara style={{lineHeight: '20px'}}>
                {getContentText(0, 'para_1', '"Surrounded by water, soaring above the clouds"')}
              </ContentPara>
              <ContentPara style={{lineHeight: '20px'}}>
                {getContentText(0, 'para_2', 'This former villa of Heungseon Daewongun, adorned with stately pavilions and age-old pine trees, is more than just a relic of the Joseon Dynasty — it\'s the chosen backdrop for our vows.')}
              </ContentPara>
            </DetailsParas1>
            <DetailPara2 style={{lineHeight: '20px'}}>
              {getContentText(0, 'para_3', 'As we celebrate love and unity, Seokpajeong will stand witness, merging its timeless elegance with the promise of our shared future.')}
            </DetailPara2>
            <AddressInfo style={{lineHeight: '20px'}}>
              {getContentText(0, 'para_4', '4-1 Changuimun-ro 11-gil, Jongno-gu, Seoul')}
            </AddressInfo>
          </DetailsSection>
        </VenueDescription>
        <ModalAssetGroups>
          <ModalConnector src={ModalConnectorImg} />
          <FirstModalAssetGroup mobileDirectionLayout='right' desktopDirectionLayout='top'>
            <VenuePic1 src={CeremonyImage} style={{maxWidth: '280px'}} />
            <ImageDescription>
              <HandType src={CeremonyHandType} />
              <DescriptionPara style={{lineHeight: '20px', width: '169px'}}>
                {getContentText(1, 'para_1', 'Join us for the ceremony in front of Neoreok Bawi.')}
              </DescriptionPara>
            </ImageDescription>
          </FirstModalAssetGroup>
          <SecondModalAssetGroup mobileDirectionLayout='left' desktopDirectionLayout='bottom' style={{maxWidth: '264px', marginBottom: '54px'}}>
            <VenuePic1 src={BridesRoom} style={{maxWidth: '264px'}} />
            <SecondImageDescription>
              <HandType src={BridesRoomHandType} />
              <DescriptionPara style={{lineHeight: '20px', width: '169px'}}>
                {getContentText(2, 'para_1', 'The heart of the pavilion, Seokpajeong. Here, the moment of the bride\'s preparation unfolds.')}
              </DescriptionPara>
            </SecondImageDescription>
          </SecondModalAssetGroup>
          <ThirdModalAssetGroup mobileDirectionLayout='right' desktopDirectionLayout='top' style={{maxWidth: '350px'}}>
            <VenuePic1 src={ReceptionTree} style={{maxWidth: '350px'}} />
            <ThirdImageDescription>
              <HandType src={ReceptionHandType} />
              <DescriptionPara style={{lineHeight: '20px', width: '169px'}}>
                {getContentText(3, 'para_1', 'Mingle beneath Chunsesong, the 600 years old ancient pine.')}
              </DescriptionPara>
            </ThirdImageDescription>
          </ThirdModalAssetGroup>
          <FourthModalAssetGroup mobileDirectionLayout='left'>
            <ImageDescription>
              <HandType src={BanquetHandType} />
              <DescriptionPara style={{lineHeight: '20px', width: '169px'}}>
                {getContentText(4, 'para_1', 'For the Banquet, we gather in the Jogak Gongwon, surrounded by artful sculptures and shared joy.')}
              </DescriptionPara>
            </ImageDescription>
            <VenuePic1 src={Banquet} style={{maxWidth: '460px'}} />
          </FourthModalAssetGroup>
        </ModalAssetGroups>
      </ModalContent>
    </ModalWrapper>,
    document.getElementById('root')
  );
}

export default VenueModal