import styled from 'styled-components'

// export const TimelineContainer = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   min-height: 100vh;
//   position: absolute;
// `

// export const ResponsiveContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   max-width: 1110px;
//   height: 100vh;
// `

// export const HeaderContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-bottom: 30px;
// `

// export const Heading = styled.h1`
//   text-align: center;
//   color: #171f46;
//   font-family: 'Roboto';
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 2.7;
//   margin: 0px;
//   @media screen and (min-width: 768px) {
//     font-size: 20px;
//   }
// `

// export const CCBPHeading = styled.span`
//   color: #2b237c;
//   font-family: 'Roboto';
//   font-weight: 500;
//   font-size: 24px;
//   margin: 0px;
//   @media screen and (min-width: 768px) {
//     font-size: 30px;
//   }
// `

export const Timeline = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 58px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
`;

export const Event = styled.div`

  position: relative;
  padding: 10px;
  padding-top: 0px;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    left: calc(-242px + 50vw);
    top: 8px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;

    @media(max-width: 440px) {
      left: 69px;
    }
  }
`;

export const StraightLine = styled.span`
  position: absolute;
  left: calc(-237px + 50vw);
  top: 8px;
  background-color: #000;
  width: 0.5px;
  height: 100%;
  z-index: -1;

  @media(max-width: 440px) {
    left: 74px;
  }
`

export const NoBulletEvent = styled(Event)`
  padding-bottom: 50px;
  &::before {
    content: '';
    background-color: transparent
  }
`

export const Content = styled.div`
  max-width: 414px;
  font-size: 18px;
  font-family: Helvetica;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3px;

  @media(max-width: 440px) {
    margin-left: 90px;
  }
`

export const ContentTitle = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
`

export const ContentPara = styled.p`
  margin-bottom: 5px;
`

export const TimelineContainer = styled.div`
  // position: absolute;
  // margin: auto;
  // left: 0;
  // right: 0;
  // font-family: Helvetica;

  // background-position-x: center;
  // background-position-y: bottom;
  // background-size: cover;
  // background-repeat: no-repeat;

  @media(max-width: 750px) {
    overflow: hidden;
  }
`

export const Greeting = styled.div`
  max-width: 414px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  text-align: left;
  line-height: 20.4px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
`

export const TimelineTitle = styled.div`
  text-align: center;
  font-size: 42px;
  font-weight: 500;
  line-height: 47px;
  margin-top: 100px;

  @media(max-width: 440px) {
    font-size: 36px;
  }
`

export const DearGuest = styled.span`
  padding-bottom: 10px;
`

export const VenueImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 29px;
  max-width: 400px;
  margin-top: 29px;
`

export const WeddingDetails = styled.div`
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  > p {
    font-size: 16px;
  }
`

export const TimlinePhotoAsset = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 58px;
  width: 100%
`

export const JoinedTopperStatic = styled.img`
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const Year = styled.div`
  margin-left: -98px;
  position: absolute;
`

export const CakePlatformEl = styled.img`
  width: 230px;
  bottom: 0;

  z-index: 2;
  right: 0;
  left: 10%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const TimelineTopImg = styled.img`
  width: 2480px;
  height: 1531px;
  position: absolute;
  top: -1527px;
  left: calc(-1240px + 50vw);

  @media(max-width: 440px) {
    left: -929px;
    // top: 208px;
  }
`

export const TimelineFirstWiggle = styled.img`
  width: ${props => props.language === 'ko' ? '43px' : '51px'};
  height: auto;
  position: absolute;
  top: ${props => props.language === 'ko' ? '11px' : '7px'};
  left: ${props => props.language === 'ko' ? 'calc(-264px + 50vw)' : 'calc(-269px + 50vw)'};

  @media(max-width: 440px) {
    left: ${props => props.language === 'ko' ? '45px' : '36px'};
    width: ${props => props.language === 'ko' ? '47px' : '61px'};
  }
`

export const TimelineEndWiggle = styled.img`
  width: 148px;
  height: 1035px;
  position: absolute;
  top: -260px;
  left: calc(-317px + 50vw);

  @media(max-width: 440px) {
    left: -7px;
  }
`