import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TimlinePhotoAsset } from '../jayAndKaitlinTimeline/styledComponents'

const RotatingImage = styled(TimlinePhotoAsset)`
  opacity: 1;
  transition: opacity 2s linear;
  max-width: ${props => `${props.maximumWidth}px`};
`

const ImageRotation = ({ images, maximumWidth, customStyles = {} }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 1000)

    return () => clearInterval(interval)
  }, [images.length])

  console.log(currentIndex, 'currentIndex')

  return (
    <RotatingImage
      src={images[currentIndex]}
      maximumWidth={maximumWidth}
      style={customStyles}
    />
  )
}

export default ImageRotation
