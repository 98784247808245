import React, { useEffect, useState } from 'react'
import Polaroid1 from '../../assets/polaroid1.png'
import Polaroid2 from '../../assets/polaroid2.png'
import Polaroid3 from '../../assets/polaroid3.png'
import Polaroid4 from '../../assets/polaroid4.png'
import Polaroid5 from '../../assets/polaroid5.png'
import Polaroid6 from '../../assets/polaroid6.png'
import Polaroid7 from '../../assets/polaroid7.png'
import Childhood1 from '../../assets/childhood1.png'
import Childhood2 from '../../assets/childhood2.png'
import Childhood3 from '../../assets/childhood3.png'
import Childhood4 from '../../assets/childhood4.png'
import Childhood5 from '../../assets/childhood5.png'
import Seoul1 from '../../assets/seoul1.png'
import Seoul2 from '../../assets/seoul2.png'
import Seoul3 from '../../assets/seoul3.png'
import Seoul4 from '../../assets/seoul4.png'
import Seoul5 from '../../assets/seoul5.png'
import Vegas1 from '../../assets/vegas1.png'
import Vegas2 from '../../assets/vegas2.png'
import Vegas3 from '../../assets/vegas3.png'
import Vegas4 from '../../assets/vegas4.png'
import Vegas5 from '../../assets/vegas5.png'
import Vegas6 from '../../assets/vegas6.png'
import Vegas7 from '../../assets/vegas7.png'
import Vegas8 from '../../assets/vegas8.png'
import Vegas9 from '../../assets/vegas9.png'
import Vegas10 from '../../assets/vegas10.png'
import Mowage1 from '../../assets/mowage1.png'
import Mowage2 from '../../assets/mowage2.png'
import Mowage3 from '../../assets/mowage3.png'
import Mowage4 from '../../assets/mowage4.png'
import Mowage5 from '../../assets/mowage5.png'
import Mowage6 from '../../assets/mowage6.png'
import Mowage7 from '../../assets/mowage7.png'
import Mowage8 from '../../assets/mowage8.png'
import Mowage9 from '../../assets/mowage9.png'
import MowageMobile1 from '../../assets/mowage-mobile1.png'
import MowageMobile2 from '../../assets/mowage-mobile2.png'
import MowageMobile3 from '../../assets/mowage-mobile3.png'
import MowageMobile4 from '../../assets/mowage-mobile4.png'
import MowageMobile5 from '../../assets/mowage-mobile5.png'
import MowageMobile6 from '../../assets/mowage-mobile6.png'
import MowageMobile7 from '../../assets/mowage-mobile7.png'
import MowageMobile8 from '../../assets/mowage-mobile8.png'
import MowageMobile9 from '../../assets/mowage-mobile9.png'
import Topper1 from '../../assets/topper1.png'
import Topper2 from '../../assets/topper2.png'
import Topper3 from '../../assets/topper3.png'
import Topper4 from '../../assets/topper4.png'
import VenueIllustration from '../../assets/venue.png'
import Duckies from '../../assets/duckies.png'
import TimelineTop from '../../assets/top-line.svg'
import TimelineBottom from '../../assets/timeline-bottom.svg'
import TimelineSquigggle from '../../assets/timeline-squiggle.svg'
import { 
  Timeline,
  Event,
  StraightLine,
  NoBulletEvent,
  Content,
  ContentTitle,
  ContentPara,
  TimelineContainer,
  Greeting,
  TimelineTitle,
  DearGuest,
  VenueImage,
  WeddingDetails,
  TimlinePhotoAsset,
  JoinedTopperStatic,
  Year,
  CakePlatformEl,
  TimelineTopImg,
  TimelineFirstWiggle,
  TimelineEndWiggle } from './styledComponents'
import ImageRotation from '../imageRotation'

const JayAndKaitlinTimeline = ({contentData, guestName, language}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const getTimelineText = (index, textType, backupText) => {
    const events = contentData.events || []
    if (events.length > index) {
      const content = events[index][textType][language]
      return content === undefined ? backupText : content
    }
    return backupText
  }

  const getWeddingInviteGreeting = (textDetail, backupText) => {
    const weddingInviteGreeting = contentData.wedding_invite || undefined
    if (weddingInviteGreeting !== undefined) { 
      const content = weddingInviteGreeting[textDetail][language] || backupText
      return content
    }
    return backupText
  }

  const getEventDetails = (textDetail, backupText) => {
    const eventDetails = contentData.event_info || undefined
    if (eventDetails !== undefined) { 
      const content = eventDetails[textDetail][language] || backupText
      return content
    }
    return backupText
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 750;

  return (
    <>
      <ImageRotation customStyles={{marginBottom: '15px', marginTop: '80px'}} maximumWidth={394} images={[Topper1, Topper2, Topper3, Topper4]} />
      <TimelineContainer>
        <Greeting>
          <DearGuest>{language === 'ko' ? `${guestName} 님` : `Dear ${guestName}`},</DearGuest><br />
          <p style={{marginTop: "10px", lineHeight: "18px"}}>{getWeddingInviteGreeting('para_1', 'With blessings of our families,  Jay and Kaitlin  invite you to join a cherished moment of love and union.')}</p>
          <p style={{marginTop: "10px", lineHeight: "18px"}}>{getWeddingInviteGreeting('para_2', 'We request the honor of your presence at our wedding.')}</p>
        </Greeting>
        <WeddingDetails>
          <p style={{marginTop: "0px", lineHeight: "18px"}}>{getEventDetails('ceremony_time', '12:30 PM')}</p>
          <p style={{marginTop: "0px", lineHeight: "18px"}}>{getEventDetails('ceremony_date', 'The 5th of November')}</p>
          <p style={{marginTop: "15px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('ceremony', 'Ceremony')}</p>
          <p style={{marginTop: "0px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('ceremony_venue', 'Seokpajeong')}</p>
          <p style={{marginTop: "0px", marginBottom: '10px', lineHeight: "18px"}}>{getEventDetails('ceremony_street_address', '4-1, Changuimun-ro 11-gil')}</p>
          <p style={{marginTop: "0px", marginBottom: '10px', lineHeight: "18px"}}>{getEventDetails('ceremony_city', 'Jongno-gu, Seoul')}</p>
        </WeddingDetails>
        <VenueImage src={VenueIllustration} />
        <WeddingDetails>
          <p style={{marginTop: "15px", lineHeight: "18px"}}>{getEventDetails('after_party_time', '4:00 PM')}</p>
          <p style={{marginTop: "15px", lineHeight: "18px"}}>{getEventDetails('after_party_date', 'The 5th of November, 2023')}</p>
          <p style={{marginTop: "15px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('afterparty', 'Afterparty')}</p>
          <p style={{marginTop: "0px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('after_party_venue', 'Ori')}</p>
          <p style={{marginTop: "0px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('after_party_street_address', '4, Sinheung-ro 12-gil')}</p>
          <p style={{marginTop: "0px", marginBottom: '0', lineHeight: "18px"}}>{getEventDetails('after_party_city', 'Yongsan-gu, Seoul')}</p>
        </WeddingDetails>
        <TimelineTitle>
          Ever thine, Ever mine,<br />Ever ours
        </TimelineTitle>
        <p style={{textAlign: "center"}}>Our Story</p>
        <Timeline>
          <ImageRotation maximumWidth={759} images={[Childhood1, Childhood2, Childhood3, Childhood4, Childhood5]} customStyles={{marginBottom: '40px'}} />
          <Event>
            <TimelineTopImg width={2480} height={1531} src={TimelineTop}/>
            <TimelineFirstWiggle language={language} src={TimelineSquigggle} />
            <Content>
              <Year>2002</Year>
              <ContentTitle>{getTimelineText(0, 'title', 'Bundang Beginnings')}</ContentTitle>
              <ContentPara>
                {getTimelineText(0, 'para_1', 'On a sunny day, amidst the chatter of school kids and the aroma of chalk, Jay \'accidentally\' flicked a paper plane that landed on Kaitlin\'s desk.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(0, 'para_2', 'As she unfolded it, she found a doodled smiley with the words, "Hi there!"')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(0, 'para_3', 'From secret snack swaps during lunch breaks to conspiring for paired projects, Bundang bore witness to the playful beginning of an inseparable duo.')}
              </ContentPara>
            </Content>
          </Event>
          <ImageRotation customStyles={{marginTop: '130px'}} maximumWidth={360} images={[Polaroid1, Polaroid2, Polaroid3, Polaroid4, Polaroid5, Polaroid6, Polaroid7]}/>
          <Event>
            <StraightLine />
            <Content>
              <Year>2019</Year>
              <ContentTitle>{getTimelineText(1, 'title', 'San Francisco Serendipity')}</ContentTitle>
              <ContentPara>
                {getTimelineText(1, 'para_1', 'Years had rolled by, paths had diverged, and both Jay and Kaitlin had ventured far from their Korean origins, exploring the expansive landscapes of the States.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(1, 'para_2', 'Prompted by a mix of nostalgia and a dash of boldness, Kaitlin initiated a meet-up with Jay in the lively streets of San Francisco.')}
              </ContentPara>
              <ContentPara>
              {getTimelineText(1, 'para_3', 'Little did they know, amidst the city\'s dynamic nightlife and the sunlit vineyards, their bond would be rekindled, bringing a hint of unexpected excitement into their lives.')}
              </ContentPara>
            </Content>
            <ImageRotation maximumWidth={820} images={[Seoul1, Seoul2, Seoul3, Seoul4, Seoul5]} customStyles={{marginTop: '140px'}} />
          </Event> 
          <Event>
            <StraightLine />
            <Content>
              <Year>2021</Year>
              <ContentTitle>{getTimelineText(2, 'title', 'A Seoulful Connection')}</ContentTitle>
              <ContentPara>
                {getTimelineText(2, 'para_1', 'What was meant to be a three-week visit for Jay turned into two unforgettable years back home in Seoul, South Korea.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(2, 'para_2', 'As the vibrant streets of Seoul quietened amidst the pandemic, Jay and Kaitlin took this time to rediscover the country.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(2, 'para_3', 'In a time when the world felt distant, they found depth and closeness in their motherland.')}
              </ContentPara>
            </Content>
            {
              isMobile ?
                <ImageRotation customStyles={{marginTop: '140px'}} maximumWidth={530} images={[MowageMobile1, MowageMobile2, MowageMobile3, MowageMobile4, MowageMobile5, MowageMobile6, MowageMobile7, MowageMobile8, MowageMobile9]} />
              : <ImageRotation customStyles={{marginTop: '140px', paddingBottom: '60px'}} maximumWidth={1708} images={[Mowage1, Mowage2, Mowage3, Mowage4, Mowage5, Mowage6, Mowage7, Mowage8, Mowage9]}/>
            }          
          </Event>
          <Event style={{marginTop: '-60px'}}>
            <StraightLine />
            <Content>
              <Year>2022</Year>
              <ContentTitle>{getTimelineText(3, 'title', 'Stars and Strings in Ulleungdo')}</ContentTitle>
              <ContentPara>
                {getTimelineText(3, 'para_1', 'On the tranquil shores of Ulleungdo, beneath a canopy of stars, Jay surprised Kaitlin with a handcrafted string ring. The wind playfully extinguished their candles, but her radiant smile outshone the night.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(3, 'para_2', 'On the trip back to the mainland, seasickness hit hard. Chaotic scene was a hilarious counterpoint to their idyllic engagement.')}
              </ContentPara>
            </Content>
            <ImageRotation maximumWidth={480} images={[Vegas1, Vegas2, Vegas3, Vegas4, Vegas5, Vegas6, Vegas7, Vegas8, Vegas9, Vegas10]} customStyles={{marginTop: '140px'}} />
          </Event>
          <Event>
            <TimelineEndWiggle src={TimelineBottom} />
            <Content>
              <Year>2023</Year>
              <ContentTitle>{getTimelineText(4, 'title', 'Viva Las Vegas')}</ContentTitle>
              <ContentPara>
                {getTimelineText(4, 'para_1', 'From San Francisco\'s hills to Las Vegas\'s glitz, Jay and Kaitlin arrived in the Marriage Capital. In the pink Cadillac, the ceremony, intimate and powerful, was charged with emotion.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(4, 'para_2', 'After sealing their vows, they lost themselves in the city\'s rhythm. A night of poolside dancing amidst the city\'s pulsating heartbeat, celebrated their union.')}
              </ContentPara>
              <ContentPara>
                {getTimelineText(4, 'para_3', 'They found profound simplicity in their elopement, proving love\'s most powerful moments often come unscripted.')}
              </ContentPara>
            </Content>
          </Event>
          <TimlinePhotoAsset src={Duckies} style={{maxWidth: '128px', marginTop: '245px'}} />
          <NoBulletEvent>
            <Content>
              <ContentPara style={{textAlign: 'center'}}>
                {contentData?.timeline_close?.closer[language] || 'We hope you make it to our special day.'}
              </ContentPara>
            </Content>
          </NoBulletEvent>
        </Timeline>
      </TimelineContainer>
    </>

  );
};

export default JayAndKaitlinTimeline
