import { useEffect, useState } from 'react'
import yaml from 'js-yaml'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
// import Kaitlin from '../../assets/kaitlin-1.svg'
// import Jay from '../../assets/jay-1.svg'
import JayAndKaitlinTimeline from '../jayAndKaitlinTimeline'
// import WeddingCakeInteractive from '../weddingCakeInteractive'
// import RSVPRest from '../../assets/rsvp-rest.svg'
// import RSVPHover from '../../assets/rsvp-hover.svg'
import VenueStaticWeb from '../../assets/navButtons/venue-static-web.png'
import VenueMobile1 from '../../assets/navButtons/venue-mobile-1.png'
import VenueMobile2 from '../../assets/navButtons/venue-mobile-2.png'
import VenueGif from '../../assets/navButtons/venue-button-gif.gif'
import VenueModal from '../VenueModal'
import RSVPStaticWeb from '../../assets/navButtons/rsvp-static-web.png'
import RSVPGif from '../../assets/navButtons/rsvp-gif.gif'
import RSVPMobileFrame1 from '../../assets/navButtons/rsvp-mobile-1.png'
import RSVPMobileFrame2 from '../../assets/navButtons/rsvp-mobile-2.png'
import ImageRotation from '../imageRotation'


// const draggablePeople = [
//   {
//     id: "kaitlin",
//     asset: Kaitlin,
//     alignment: "left"
//   },
//   {
//     id: "jay",
//     asset: Jay,
//     alignment: "right"
//   }
// ]

const MainContainer = styled.div``

const NavButton = styled.a`
  cursor: pointer;
  width: 20%;
  max-width: 225px;
  padding-left: 28px;
  padding-top: 28px;
  padding-right: 28px;
  @media(max-width: 750px) {
    min-width: 157px;
  }
`

const NavButtonImg = styled.img`
  width: 100%;
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
`

function HomePage() {
  const location = useLocation()
  // const [draggingCompleted, setDraggingCompleted] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [timelineContentData, setTimelineContentData] = useState([])
  const [modalContentData, setModalContentData] = useState([])
  const [RSVPAsset, setRSVPAsset] = useState(RSVPStaticWeb)
  const [VenueButtonAsset, setVenueButtonAsset] = useState(VenueStaticWeb)
  const queryParams = new URLSearchParams(location.search)
  const guestNameParam = queryParams.get('guestName')
  const siteLanguage = queryParams.get('lang')
  const guestName = guestNameParam || 'Esteemed Guest'
  const language = siteLanguage || 'en'
  const RSVPLink = siteLanguage === 'en' ? 'https://9vxlgvxyc3p.typeform.com/to/Z5l8L6xc' : 'https://9vxlgvxyc3p.typeform.com/to/YwshoBwp'

  const openModal = () => {
    setShowModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setShowModal(false);
    document.body.style.overflow = "scroll";
  };

  const toggleRSVPAsset = () => {
    const newAsset = RSVPAsset === RSVPStaticWeb ? RSVPGif : RSVPStaticWeb
    setRSVPAsset(newAsset)
  }

  const toggleVenueButtonAsset = () => {
    const newAsset = VenueButtonAsset === VenueStaticWeb ? VenueGif : VenueStaticWeb
    setVenueButtonAsset(newAsset)
  }

  useEffect(() => {
    async function fetchTimelineData() {
      try {
        const response = await fetch('/content.yaml')
        const yamlText = await response.text()
        const yamlData = yaml.load(yamlText)
        setTimelineContentData(yamlData)
      } catch (error) {
        console.error('Error reading YAML file:', error)
      }
    }

    async function fetchModalData() {
      try {
        const response = await fetch('/modal-content.yaml')
        const yamlText = await response.text()
        const yamlData = yaml.load(yamlText)
        setModalContentData(yamlData)
      } catch (error) {
        console.error('Error reading YAML file:', error)
      }
    }

    fetchTimelineData()
    fetchModalData()
  }, [])

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 750;

  return (
    <MainContainer>
      {showModal && <VenueModal closeModal={closeModal} language={language} modalContentData={modalContentData} />}
      <Navigation>
        <NavButton href={RSVPLink} target='_blank'>
          {isMobile ? <ImageRotation maximumWidth={157} images={[RSVPMobileFrame1, RSVPMobileFrame2]} /> : <NavButtonImg onMouseOver={toggleRSVPAsset} onMouseOut={toggleRSVPAsset} src={RSVPAsset} />}
        </NavButton>
        <NavButton>
          {isMobile ? <ImageRotation maxiumumWidth={136} images={[VenueMobile1, VenueMobile2]} /> : <NavButtonImg width={234} onMouseOver={toggleVenueButtonAsset} onMouseOut={toggleVenueButtonAsset} src={VenueButtonAsset} onClick={openModal} /> }
        </NavButton>
      </Navigation>
      {/* <WeddingCakeInteractive 
        draggables={draggablePeople}
        draggingCompleted={draggingCompleted}
        setDraggingCompleted={setDraggingCompleted} /> */}
      <JayAndKaitlinTimeline contentData={timelineContentData} guestName={guestName} language={language} />
    </MainContainer>
  )
}

export default HomePage